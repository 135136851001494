<template>
<div>
<h1>{{$route.params.slug}}</h1>
<h2></h2>
<FullCalendar ref="fullCalendar" :options="calendarOptions"/>

</div>
</template>

<script>

import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import googleCalendarPlugin from '@fullcalendar/google-calendar'
import listPlugin from '@fullcalendar/list'
    export default {
        name: "event",
        components: {
    FullCalendar 
  },
        data() {
            return {
      calendarOptions: {
        plugins: [ dayGridPlugin, interactionPlugin, googleCalendarPlugin, listPlugin ],
        initialView: 'listYear',
        googleCalendarApiKey: 'AIzaSyATa6jtDDyPV20MPoYi6uckNBx41kB-buQ',
        eventClick: this.handleDateClick,
        events: {
          googleCalendarId: 'c_t0eruqtfu1a958ih7elgd2f1a0@group.calendar.google.com'
        },
        cal: ''
      }
    };
        },
        mounted: function() {
            
            console.log(this.$refs.fullCalendar);
            let calendar = this.$refs.fullCalendar.getApi();
            console.log(calendar.getEvents());
				}
            }

</script>