<template>
<div id="container">
<div id="map"></div>
<div id="features">
<section id="intro" class="active">
<br><br><br><br>
<h2>Introduction</h2>
<p>
When William Still arrived in Philadelphia in the Spring of 1844, he was slightly disappointed by the few opportunities in the city. He worked several jobs that either paid low wages, or offered seasonal employment. Still considered most of these positions a “labor in vain,” but took the work he could find because “a half loaf is better than no bread.” During his first two years in Philadelphia, he earned money by “threshing clam-shells,” hauling wood and driving carts on the wharf, selling second-hand clothes, building houses and other, valuable, but menial work. None of these positions quenched his thirst for intellectual stimulation, unconventional education, or the self-improvement he valued so highly. It must have been reminiscent of his school days. Once again, manual labor was standing in the way of his studies. But rainy days no longer gave him a chance to learn.
</p>
</section>
<section id="elwyn">
<h2>Elizabeth Elwyn's House</h2>
<p>
An opportunity for self-improvement and intellectual growth presented itself in an unexpected way. He secured a job as a housekeeper for Elizabeth Langdon Elwyn, an elite elderly widow of high society. She was a New Hampshire local, but was spending a few months in Philadelphia. During her time in the city, she lived with her daughter Emma Matilda Elwyn, at 1514 Chestnut St. Elizabeth Elwyn helped him gain what he had sought for so long: advanced literacy. He served her well, “went through with his labors cautiously and correctly,” and in return she opened her library to him. Elwyn would choose books for Still to read after perusing her grand collection. This was a great contrast to the experience he had had in his early education. She “treated Still without prejudice,” and engaged in “intellectual conversations with him.” This was a major turning point for Still and would enable him to pursue a professional career that might have been closed off to him without it. He had followed the advice from the author of the article in The Colored American. </p>
<p>
Working for Elizabeth Elwyn in Emma Elwyn’s home allowed William Still to “educate and refine” himself. The conversations and intellectual growth he had under her employment allowed him to develop the “efficient qualifications for” an important job. He was meeting the goals he set in his youth, and improved himself intellectually, culturally, and educationally. He had been working for her for a little more than one year, when she moved into the old Butler mansion, on 13th and Walnut (now the Philadelphia Club). She took him with her when she moved, and his duties, and privileges went relatively unchanged. Six months later, Elizabeth Elwyn left Philadelphia, to stay with her other daughter, Emily Sophia Elwyn, and her son-in-law, Col. John Erving. But before she left, she provided him with a splendid reference, which opened up an avenue to a lot more employment opportunities.
</p>
</section>
<section id="antislavery">
<h2>Pennsylvania Anti-Slavery Society</h2>
<p>
<img src="../assets/pas-members.jpeg" style="max-width:100%;
  padding-left:0px; float:none">
William Still submitted a letter application to the Pennsylvania Anti-Slavery Society at 105 N. 5th Street. He took the job despite the low pay of $3.75 per week, with the hope of meeting prominent abolitionists. However, “regular underground railroad work formed no part of the duties” he was given. To earn extra income, he worked as a Janitor at the Apprentice’s Library. Here again, demonstrating his dedication to education and literacy. His prioritization of education can be further acknowledged in his efforts to found and fund a night-school.  However, the cost of the project was far higher than his two salaries combined. He ended the school and abandoned the project. Soon he earned a promotion in the Pennsylvania Abolition Society, no longer serving as a messenger, but a clerk. This promotion came with a pay raise that allowed him to resign as the librarian janitor. 
</p>
</section>
<section id="residence1">
<h2>William Still Residence #1</h2>
<p>

</p>
</section>
<section id="residence2">
<h2>William Still Residence #2</h2>

<p>
1850 was an important year for abolitionist efforts. The Fugitive Slave Law of 1850 changed the political climate of the North, and energized abolitionists in Philadelphia. The law required northerners to participate in an institution many had been content to ignore. And whether northerners were swayed because they were reluctant to be complicit, recognized the evils of the institution, or felt their rights were being infringed, their support helped move the Underground Railroad aboveground. Shortly after its passage, the Pennsylvania Anti-Slavery Society formed the Vigilance Committee to secretly help enslaved people escape. William Still was named the chairman of the Vigilance Committee and under his direction, Philadelphia became a major station on the Underground Railroad. He would go on to aid as many as 800 enslaved people find freedom. </p>
<img src="../assets/residence-2.png" style="max-height:500px;
  padding-left:0px; float:none">
<p>
The promotion gave William and Letitia Still the funds to improve their living conditions. They moved to 17 Ronaldson Street (now 625 S. Delhi Street),and his work moved with him. His house served as a station on the Underground Railroad. Between 1852 and 1855, Still kept records that list 238 enslaved people stopped in his home on their journey to freedom. Still’s house was an especially successful ‘railway station’ because he shared the street with many other Black property owners, and the area with major Black institutions (churches, schools, and private residences.) The high percentage of Black residents allowed fugitives escaping slavery to blend in to the locals and avoid detection. Ronaldson gained prominence in the African American community until the early twentieth century.
</p>
</section>
<section id="lebanon">
<h2>African American Lebanon Cemetery</h2>
<img src="../assets/lebanon.jpg" style="max-width:100%;
  padding-left:0px; float:none">
<p>
The Underground Railroad required two things to function: Collaboration and Confidentiality. William Still ignored the second when he began taking notes about enslaved people’s escapes. Had the notes been found, hundreds of enslaved people would lose their freedom, and hundreds of abolitionists might lose their freedom as well. To protect the notes, he hid them in the African American Lebanon Cemetery. His desire to document the accomplishments of self-emancipation are a culmination of the lessons he learned about slavery, and equality. Literature serves as a tribute to accomplishments, awarding certain stories the right to documentation and preservation. Literacy allows those accomplishments to be recognized, acknowledged, and valued. He believed the best way to earn equality was to highlight Black success, and he considered emancipation one such success. It follows that he would record those successes, and then distribute them when he thought white Americans needed to read them. Those notes eventually became the book, The Underground Railroad, published many times, but originally in 1867. This book helped reunite families separated by the slave trade, including his own. 
</p>
</section>

</div>
  </div>
</template>

<script>
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

export default {
    mounted(){
      mapboxgl.accessToken =
        "pk.eyJ1IjoiY2hyYWRpbCIsImEiOiJja28wdnN0ZWMwZ3V3Mndta2Q2bm81bGtjIn0.Rt-7EwN5ftoTjzPYGhJl4Q";
      let map = new mapboxgl.Map({
        container: "map",
        style: "mapbox://styles/mapbox/light-v9",
        center: [-75.16625,39.95093],
        zoom: 13,
      });

map.on('load', () => {
  map.addSource('points', {
    'type': 'geojson',
    'data': {
      'type': 'FeatureCollection',
        'features': [
          {'type': 'Feature',
            'geometry': {
              'type': 'Point',
              'coordinates': [-75.16625,39.95093]
            },
            'properties': {
              
            }
          },
          {'type': 'Feature',
            'geometry': {
              'type': 'Point',
              'coordinates': [-75.16625,39.95093]
            },
            'properties': {
              'title': "Elizabeth Elwyn's house"
            }
          },
          {'type': 'Feature',
            'geometry': {
              'type': 'Point',
              'coordinates': [-75.1484, 39.95267]
            },
            'properties': {
              'title': 'Pennsylvania Antislavery Society'
            }
          },
          {'type': 'Feature',
            'geometry': {
              'type': 'Point',
              'coordinates': [-75.161148, 39.943531]
            },
            'properties': {
              'title': "William Still's Residence #1"
            }
          },
          {'type': 'Feature',
            'geometry': {
              'type': 'Point',
              'coordinates': [-75.15812, 39.94205]
            },
            'properties': {
              'title': "William Still's Residence #2"
            }
          },
          {'type': 'Feature',
            'geometry': {
              'type': 'Point',
              'coordinates': [-75.15812, 39.94205]
            },
            'properties': {
              'title': 'African American Lebanon Cemetery'
            }
          }
        ]
      }
  });

  map.addLayer({
    'id': 'points',
    'type': 'circle',
    'source': 'points',
    'paint': {
      'circle-radius': 6,
      'circle-color': '#42b983'
    }
  });

  map.addLayer({
    'id':'labels',
    'type':'symbol',
    'source':'points',
    'layout': {
      'text-field': ['get', 'title'],
      'text-font': [
        'Open Sans Semibold',
        'Arial Unicode MS Bold'
      ],
      
      'text-offset': [0, 1.25],
      'text-anchor': 'top'
    },
    'paint': {
      'text-color': '#597774'
    }

  })
});

var chapters = {
  'intro': {
    bearing: 0,
    center: [-75.16625,39.95093],
    zoom: 15,
    pitch: 0
  },
  'elwyn': {
    duration: 6000,
    center: [-75.16625,39.95093],
    bearing: 0,
    zoom: 15,
    pitch: 0
  },
  'antislavery': {
    bearing: 0,
    center: [-75.1484, 39.95267],
    zoom: 15,
    speed: 0.6,
    pitch: 0
  },
  'residence1': {
    bearing: 0,
    center: [-75.161148, 39.943531],
    zoom: 15
  },
  'residence2': {
    bearing: 0,
    center: [-75.15812, 39.94205],
    zoom: 15,
    pitch: 0,
    speed: 0.5
  },
  'lebanon': {
    bearing: 0,
    center: [-75.15812, 39.94205],
    zoom: 15
  }
};
 
// On every scroll event, check which element is on screen
window.onscroll = function () {
var chapterNames = Object.keys(chapters);
for (var i = 0; i < chapterNames.length; i++) {
var chapterName = chapterNames[i];
if (isElementOnScreen(chapterName)) {
setActiveChapter(chapterName);
break;
}
}
};
 
var activeChapterName = 'intro';
function setActiveChapter(chapterName) {
if (chapterName === activeChapterName) return;
 
map.easeTo(chapters[chapterName]);
 
document.getElementById(chapterName).setAttribute('class', 'active');
document.getElementById(activeChapterName).setAttribute('class', '');
 
activeChapterName = chapterName;
}
 
function isElementOnScreen(id) {
var element = document.getElementById(id);
var bounds = element.getBoundingClientRect();
return bounds.top < window.innerHeight && bounds.bottom > 300;
}
    }
  
};


</script>

<style>
body { 
  margin: 0; 
  padding: 0; 
  color: #2c3e50;
}

#map { position: absolute; top: 0; bottom: 0; width: 50%; }

#map {
position: fixed;
}

#features {
position:relative;
width: 50%;
margin-left: 50%;
font-family: sans-serif;
overflow-y: scroll;
background-color: white;
color: #2c3e50
}

section {
padding: 25px 50px;
line-height: 25px;
border-bottom: 1px solid #ddd;
opacity: 0.5;
font-size: 13px;
}

section.active {
opacity: 1;
}

section:last-child {
border-bottom: none;
margin-bottom: 200px;

img.inline-img{
  max-width:100%;
  padding:0px;
}

}
</style>